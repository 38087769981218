import React from "react";
import theme from "theme";
import { Theme } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml } from "@quarkly/components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"404"} />
		<Helmet>
			<title>
				Sparkling Clean Homes
			</title>
			<meta name={"description"} content={"Повертаємо блиск у ваш дім"} />
			<meta property={"og:title"} content={"Sparkling Clean Homes"} />
			<meta property={"og:description"} content={"Повертаємо блиск у ваш дім"} />
			<meta property={"og:image"} content={"https://uploads.quarkly.io/6672ad8f8d4a0c002030a583/images/2-4.jpg?v=2024-06-19T11:44:30.602Z"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/6672ad8f8d4a0c002030a583/images/2-4.jpg?v=2024-06-19T11:44:30.602Z"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://uploads.quarkly.io/6672ad8f8d4a0c002030a583/images/2-4.jpg?v=2024-06-19T11:44:30.602Z"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://uploads.quarkly.io/6672ad8f8d4a0c002030a583/images/2-4.jpg?v=2024-06-19T11:44:30.602Z"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://uploads.quarkly.io/6672ad8f8d4a0c002030a583/images/2-4.jpg?v=2024-06-19T11:44:30.602Z"} />
			<link rel={"apple-touch-startup-image"} href={"https://uploads.quarkly.io/6672ad8f8d4a0c002030a583/images/2-4.jpg?v=2024-06-19T11:44:30.602Z"} />
			<meta name={"msapplication-TileImage"} content={"https://uploads.quarkly.io/6672ad8f8d4a0c002030a583/images/2-4.jpg?v=2024-06-19T11:44:30.602Z"} />
		</Helmet>
		<RawHtml>
			<style place={"endOfHead"} rawKey={"666ac2cae8900d0022f35234"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});